
let model = {}

model.dataForm = {
    id: undefined,
    t: undefined,
    a: undefined,
    stat: undefined,
    desc: undefined,
    dt: undefined,
    uid: undefined,
    ip: undefined,
    dept: undefined,
    deleted: false
}
model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: false,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: "-dt",
    defaultSort: ["dt","descending"],
    fields: "id,t,a,stat,desc,dt,uid,ip,dept",
    dataSearch: {
        value: {
            t:undefined,
            a:undefined,
            uid: undefined,
            ip:undefined,
            dept:undefined,
            dt: undefined,
            desc:undefined,
            stat:undefined,
        },
        operator: {
            ip: ":regex_i:",
            desc: ":regex_i:",
            t: ":in:",
            a: ":in:",
            dt: ":timeRange:"
        },
    }
}

export default model